<template>

  <div class="row">
    <div class="col-12 card">
      <h4>Обработчики</h4>
      <div class="row justify-content-space-between top-24">
          <div class="col-6">
            <div class="row justify-content-space-between">
              <div class="text-field col-8">
                <div class="text-field__icon">
                  <input class="text-field__search" type="search" name="search" id="search" placeholder="Поиск по таблице" value="">
                  <span class="text-field__aicon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
                  </span>
                </div>
              </div>
              <div class="col-4">
                <router-link to="/algorithms/create"><button class="btn-primary"><span class="icon-Plus" style="left:4px;"></span></button></router-link>
              </div>
            </div>
          </div>
      </div>
      <div class="row">

          <table class="col-12">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Поля</th>
                <th>Статус</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              
                <tr>
                  
                  <td><router-link to="/guides/1">Автотранспорт</router-link></td>
                  <td>23</td>
                  <td><span class="icon-Check green"></span>Активный</td>
                  <td class="right"><router-link to="/guides/1"><span class="icon-more-vertical"></span></router-link></td>
                  
                </tr>
              
              <tr>
                <td><router-link to="/guides/1">Автотранспорт</router-link></td>
                <td>23</td>
                <td><span class="icon-Check gray"></span>Архив</td>
                <td class="right"><router-link to="/guides/1"><span class="icon-more-vertical"></span></router-link></td>
              </tr>
              <tr>
                <td><router-link to="/guides/1">Автотранспорт</router-link></td>
                <td>23</td>
                <td><span class="icon-Check green"></span>Активный</td>
                <td class="right"><router-link to="/guides/1"><span class="icon-more-vertical"></span></router-link></td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <ul class="page top-24">
              <li class="page_btn"><span class="icon-CaretLeft"></span></li>
              <li class="page_numbers active"> 1</li>
              <li class="page_numbers">2</li>
              <li class="page_numbers">3</li>
              <li class="page_numbers">4</li>
              <li class="page_numbers">5</li>
              <li class="page_numbers">6</li>
              <li class="page_dots">...</li>
              <li class="page_numbers"> 10</li>
              <li class="page_btn"><span class="icon-CaretRight"></span></li>
            </ul>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Обработчики"
    }
  },
};
</script>
